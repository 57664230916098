<template>
  <TTBtn
    color="default"
    fab
    @click="goBack"
  >
    <VIcon>fal fa-arrow-left</VIcon>
  </TTBtn>
</template>

<script>
export default {
  name: 'AppGoBack',
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
</style>
