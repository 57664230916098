<template>
  <VApp id="DialogStyleLayout">
    <RouterView
      name="nav"
      :drawer="drawer"
      @aside-left:toggle="drawer = $event"
    />

    <RouterView
      name="aside-left"
      :drawer="drawer"
    />

    <VMain>
      <TTView>
        <template #left>
          <VRow>
            <VCol>
              <AppGoBack />
            </VCol>
          </VRow>
        </template>
        <RouterView />
        <template #right>
          <VRow>
            <VCol>
              <TTBtn
                color="default"
                fab
              >
                <VIcon>fal fa-times</VIcon>
              </TTBtn>
            </VCol>
          </VRow>
        </template>
      </TTView>

      <RouterView name="content-footer" />
    </VMain>

    <RouterView name="aside-right" />

    <RouterView name="footer" />

    <VOverlay
      :value="loading"
      color="rgba(255, 255, 255, 1)"
      opacity="1"
    >
      <VProgressCircular
        indeterminate
        color="accent"
      />
    </VOverlay>

    <VSnackbar
      :value="snackbar.value"
      :timeout="snackbar.timeout"
      top
      :color="snackbar.color"
      @input="toggleSnack"
    >
      {{ snackbar.message }}

      <template #action="{ attrs }">
        <VBtn
          color="white"
          text
          v-bind="attrs"
          @click="toggleSnack"
        >
          закрыть
        </VBtn>
      </template>
    </VSnackbar>
  </VApp>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { toggleSnack } from '@/plugins/vuex/mutationTypes';
import TTView from '@/ui/views/TTView.vue';
import AppGoBack from '@/ui/widgets/AppGoBack.vue';

export default {
  name: 'DialogStyleLayout',
  components: { AppGoBack, TTView },
  data() {
    return {
      drawer: true,
    };
  },

  computed: {
    ...mapState(['snackbar', 'loading']),
  },

  methods: {
    ...mapMutations([toggleSnack]),
  },
};
</script>
